const universes: any = [
    'root-discovery',
    'root-perks',
    'root-settings',
    'root-search',
    'root-logout',
]

const buildNodeHierarchy = (nodesMap: Record<string, any>, node: any, ignoreList: string[] = []) => {
    const tempArray: any = []

    if (node?.children) {
        for (const child of node?.children) {
            const childNode = nodesMap[child]

            if (childNode && isValidNode(childNode, ignoreList)) {
                const {children, ...rest} = childNode

                tempArray.push({
                    ...rest,
                    children: buildNodeHierarchy(nodesMap, childNode),
                })
            }
        }
        return tempArray
    }

    return []
}

export const getMenuOptions = (nodesMap: Record<string, any>, ignoreList: string[] = []) => {
    const _unsortedmenuOptions: any = []
    const _menuOptions: any = []
    const nodeKeyArray = Object.keys(nodesMap)
    for (const nodeKey of nodeKeyArray) {
        const node = nodesMap[nodeKey]
        if (node.parent === 'root' && isValidNode(node, ignoreList)) {
            if (node?.children) {
                _unsortedmenuOptions.push({
                    ...node,
                    children: buildNodeHierarchy(nodesMap, node, ignoreList),
                })
            } else {
                _unsortedmenuOptions.push({...node})
            }
        }
    }

    universes.forEach(menuOption => {
        if(_unsortedmenuOptions.find((unsortedmenuOption) => unsortedmenuOption.id == menuOption)) {
        _menuOptions.push(_unsortedmenuOptions.find((unsortedmenuOption) => unsortedmenuOption.id == menuOption))
        }
    })

    return _menuOptions
}

const isValidNode = (node: any, ignoreList: string[]) => {
    return (
        (node.isContentNode() || node.isPortalNode() || node.isEPGNode() || node.isNavigationNode()) &&
        !ignoreList.includes(node.id)
    )
}

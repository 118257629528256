import {LoadingScreen, useConfig} from '../components'
import {useAuth} from '../authorization'
import {profileSelector, useDispatch, useSelector} from '../store'
import {useParams, useLocation} from '@solidjs/router'
import {createEffect, createSignal, Show} from 'solid-js'
import {LocalStorageKeys} from '../utils/local-storage-keys'
import {pushNotification} from 'store-actions'
import {NOTIFICATION_TYPES} from 'store-constants'
import {useNavigate} from '@solidjs/router'

export function withSecured(Component) {
    return function (props) {
        const navigate = useNavigate()
        const {getConfig} = useConfig()
        const location = useLocation()
        const {authorizationHandler} = useAuth()
        const profile = useSelector(profileSelector)
        const params = useParams()
        const [shouldAuthenticate, setShouldAuthenticate] = createSignal(false)
        const [isAuthError, setIsAuthError] = createSignal(true)
        const dispatch = useDispatch()
        createEffect(() => {
            const urlParams = new URLSearchParams(window.location.search)
            const isLogged = !!urlParams.get('isLogged') === true
            if (isLogged) {
                setShouldAuthenticate(true)
                authorizationHandler().signIn(location.pathname)
            }
            const code = urlParams.get('error')
            if (code === 'sessionExpired' && isAuthError()) {
                switch (getConfig().authErrorHandlingType) {
                    case 'notification': {
                        navigate(getConfig().authErrorRedirectionUrl || 'root-discovery')
                        dispatch(
                            pushNotification({
                                message: {
                                    tag: ['userSessionIsExpired'],
                                },
                                type: NOTIFICATION_TYPES.ERROR,
                            })
                        )
                        setIsAuthError(false)
                        break
                    }
                    case 'redirect': {
                        window.open(getConfig().authErrorRedirectionUrl, '_self')
                        setIsAuthError(false)
                        break
                    }
                    case 'error-page': {
                        setIsAuthError(false)
                        console.log('error-page-12312')
                        navigate('/auth-error')
                        break
                    }
                }
                window.history.pushState({}, '', window.location.origin + window.location.pathname)
            }
            const haveToken =
                !(code === 'sessionExpired') &&
                location.pathname === '/root-discovery' &&
                localStorage.getItem(LocalStorageKeys.OIDC_OAUTH_TOKEN) !== null
            if (
                getConfig().unauthenticatedRoutes &&
                (!getConfig().unauthenticatedRoutes.includes(location.pathname) || haveToken) &&
                !profile()
            ) {
                setShouldAuthenticate(true)
                authorizationHandler().signIn(location.pathname)
            } else {
                setShouldAuthenticate(false)
            }
        })

        return (
            <Show when={!shouldAuthenticate()} fallback={<LoadingScreen />}>
                <Component {...props} />
            </Show>
        )
    }
}

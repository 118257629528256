import { IOffer } from '@typings/tile'
import { PAYMENT_METHOD_TYPE, PERIOD_UNIT } from 'enums'
import { Abstract } from './Abstract'
/**
 *
 */

/**
 * An offer describes all purchasing informations to be displayed to the user
 * to make his choice among the list of offers for an asset/product.
 * Example: A VOD (one item in a catalog) could be available for purchase using two offers (SD, HD, UHD content)
 * Or subscription could be available for 10$ per month and in the same time for 30$ a year.
 *
 * This model is to handle all kind of purchase.
 *
 * @property {String} offerId Offer identifier
 * @property {String} offerType Required by some backend for transaction (ufinity) --> Free text
 * @property {Number} price Price offered to the user to purchase the product
 * @property {Number} [originalPrice] Original price associated with the offer
 * @property {Number} period Period duration for the offer
 * @property {Number} periodUnit Could be Year(s), Month(s), Day(s), secondes, ms.. {@Link UNIT}
 * @property {String} [currency] Currency
 * @property {Boolean} [isPermanent] Tell us if the VOD can be bought (EST VOD) or only rented
 * @property {String} [title] Tile (Can be displayed during transaction e.g Market One)
 * @property {Number} [cycles] For recurring offers. For instance, if it is equal to 0, the offer won't be recurring ; if it's 1, the period will be repeated once, and so on (zero based value).
 * @property {Object} [iapProducts] In App Purchase Product from Google/Apple/Roku/Amazon stores tied to this offer
 * @property {Array<PaymentMethod>} [allowedPaymentMethods] In App Purchase Product from Google/Apple/Roku/Amazon stores tied to this offer (if list is empty then all payment methods are allowed)
 * For design purposes and first integration with marketONE this object is linked to the offers rather than the parent (Product) to let the user select different offers
 * Other projects have to comply with this DESIGN !!!
 */
export class Offer extends Abstract {
    id: string
    offerId: string
    offerType: string
    allowedPaymentMethods?: `${PAYMENT_METHOD_TYPE}`[]
    isActive: boolean
    title: string
    cycles: number
    period: number
    periodUnit: string | number
    price: number
    originalPrice: number
    currency: string
    isPermanent?: boolean
    oneTimeCharge?: boolean
    iapProducts?: object
    description?: string
    trial?: any

    constructor(props: IOffer) {
        super()

        const computePropValue = this._makeComputePropValue(props)

        this.id = props.id || props.offerId
        this.offerId = props.offerId
        this.offerType = props.offerType
        this.allowedPaymentMethods = computePropValue('allowedPaymentMethods', [])
        this.isActive = computePropValue('isActive', true)
        this.title = props.title
        this.cycles = props.cycles
        this.period = props.period
        this.periodUnit = props.periodUnit || Offer.UNIT.MS
        this.price = props.price
        this.originalPrice = props.originalPrice
        this.currency = props.currency
        this.isPermanent = props.isPermanent
        this.iapProducts = computePropValue('iapProducts', [])
        this.description = props.description
        this.oneTimeCharge = props.oneTimeCharge
        this.trial = props?.trial
    }

    static UNIT = PERIOD_UNIT

    /**
     * Check if payment method is allowed for this offer
     * @param {Array<PaymentMethod.PAYMENT_METHOD_TYPE>} paymentMethodTypes
     * @returns True or False
     */
    isPaymentMethodAllowed(paymentMethodTypes: `${PAYMENT_METHOD_TYPE}`) {
        if (this.allowedPaymentMethods) {
            return (
                !this.allowedPaymentMethods.length ||
                this.allowedPaymentMethods.some((x) => paymentMethodTypes.includes(x))
            )
        }
        return false
    }
}

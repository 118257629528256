// import {logActionsMiddleware} from './middlewares/logActionsMiddleware'
import {AnalyticsStoreWrapper, Firebase, getBrowserName, getDeviceData} from '../utils'
import storage from '../utils/storage'
import {logActionsMiddleware} from './middlewares/logActionsMiddleware'

export const getStore = (configureStoreFactory, config, voltApi) => {
    const {ConfigurationApi, Constants, Models, VoltError, VoltApi} = voltApi
    const configureStore = configureStoreFactory({
        setUserStorageKey: userId => {},
        initialState: {},
        config,
        Constants,
        ConfigurationApi,
        env: {},
        // eventListenergetter: client.helpers.getPlayerEventListener,
        isTablet: false,
        // licenseServerUrlGetter: client.helpers.getLicenseServerUrl,
        Models,
        OS: getBrowserName(voltApi),
        // streamUrlConverter: streamUrl => new Promise(() => streamUrl),
        // streamUrlConverter: client.helpers.convertStreamUrl,
        VoltError,
        assets: {
            images: {},
        },
        // customNodes: customNodes(),
    })

    const getFirebaseAnalyticsInstance = () => {
        const firebase = Firebase.getInstance(config?.firebaseConfig)
        if (firebase?.analytics) {
            return new AnalyticsStoreWrapper(firebase?.analytics, true, firebase.emit, false)
        }
    }

    return configureStore({
        deviceProperties: {
            platform: getBrowserName(voltApi),
        },
        isMobile: false,
        initialState: {},
        getApiInstance: () =>
            new VoltApi({
                ...config,
                platform: getBrowserName(voltApi),
                analyticsFirebaseInstance: getFirebaseAnalyticsInstance(),
            }),
        crashLogger: {
            registerUser: () => {},
            unregisterUser: () => {},
            log: () => {},
            recordError: () => {},
            crash: () => {},
        },
        errorLogger: undefined,
        isInternetReachable: () => Promise.resolve(true),
        OS: getBrowserName(voltApi),
        isTablet: false,
        exitApp: () => {},
        NavigationService: () => {},
        storage,
        screenDimensions: {
            width: window.innerWidth,
            height: window.innerHeight,
        },
        debug: {
            maxAge: 1000,
            recordReduxChanges: true,
        },
        getDeviceData: () => new Promise((resolve, reject) => resolve(getDeviceData())),
        extraMiddlewares: [logActionsMiddleware],
        // enableReduxLogger: true, // for dev only})
    })
}
